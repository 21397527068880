@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette(mat.$purple-palette, 500);
$my-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
    color: (
        primary: $my-primary,
        accent: $my-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

@include mat.all-component-themes($my-theme);

$primary: mat.get-color-from-palette($my-primary, default);
$accent: mat.get-color-from-palette($my-accent, default);
$accent400: mat.get-color-from-palette($my-accent, A400);
$primary-gradient: linear-gradient(#c68dde, #54309a);
$accent-gradient: linear-gradient(#80D8FF, #00B0FF);

html {
    overflow: hidden;
    height: 100%;
}

body {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #e6e6e6;
}

@keyframes pop-in-to-bottom {
    from {
        transform: scale3d(0, 0, 1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}

.absolute-fill {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.absolute-bottom {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
}

.relative {
    position: relative;
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
}

.no-overflow {
    overflow: hidden;
}

.overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
}

.overflow-x {
    overflow-x: auto;
}

.toolbar-z {
    z-index: 401;
}

.z-index-1 {
    z-index: 1;
}

.white {
    color: #ffffff;
    fill: #ffffff;
}

.grey {
    color: #666666;
    fill: #666666;
}

.teal {
    color: #009688;
    fill: #009688;
}

.orange {
    color:#ff3d00;
    fill: #ff3d00;
}

.text-primary {
    color: $primary;
    fill: $primary;
}

.text-accent {
    color: $accent;
    fill: $accent;
}

.sub-header {
    opacity: 0.7;
    font-size: 12px;
    transform: translate(0, -11px);
    height: 17px;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.padding-around-xs {
    padding: 2px;
}

.padding-around-sm {
    padding: 5px;
}

.padding-around-md {
    padding: 15px;
}

.horizontal-padding-sm {
    padding-left: 5px;
    padding-right: 5px;
}

.padding-top-md {
    padding-top: 15px;
}

.margin-around-sm {
    margin: 5px;
}

.margin-around-md {
    margin: 15px;
}

.margin-vertical-sm {
    margin-top: 5px;
    margin-bottom: 5px;
}

.no-margin {
    margin: 0;
}

.fixed-height-350 {
    height: 350px;
}

.fixed-height-330 {
    height: 330px;
}

.fixed-height-300 {
    height: 300px;
}

.fixed-height-185 {
    height: 185px;
}

.fixed-height-175 {
    height: 175px;
}

.fixed-height-155 {
    height: 155px;
}

.fixed-height-150 {
    height: 150px;
}

.fixed-height-125 {
    height: 125px;
}

.fixed-height-100 {
    height: 100px;
}

.fixed-height-80 {
    height: 80px;
}

.fixed-height-75 {
    height: 75px;
}

.fixed-height-55 {
    height: 55px;
}

.fixed-height-50 {
    height: 50px;
    min-height: 50px;
}

.fixed-height-45 {
    height: 45px;
}

.fixed-height-40 {
    height: 40px;
}

.fixed-height-32 {
    height: 32px;
}

.fixed-height-30 {
    height: 30px;
}

.fixed-height-28 {
    height: 28px;
}

.fixed-height-20 {
    height: 20px;
}

.fixed-height-17 {
    height: 17px;
}

.fixed-height-15 {
    height: 15px;
}

.fixed-height-10 {
    height: 10px;
}

.fixed-height-5 {
    height: 5px;
}

.fixed-width-300 {
    width: 300px;
    min-width: 300px;
}

.fixed-width-250 {
    width: 250px;
}

.fixed-width-150 {
    width: 150px;
}

.fixed-width-125 {
    width: 125px;
}

.fixed-width-100 {
    width: 100px;
}

.fixed-width-75 {
    width: 75px;
}

.fixed-width-50 {
    width: 50px;
    min-width: 50px;
}

.fixed-width-40 {
    width: 40px;
}

.fixed-width-30 {
    width: 30px;
}

.fixed-width-10{
    width: 10px;
}

.fixed-width-15 {
    min-width: 15px;
    width: 15px;
}

.fixed-width-5 {
    width: 5px;
}

.min-width-60 {
    min-width: 60px;
}

.max-width-250 {
    max-width: 250px;
}

.icon-sm {
    height: 15px !important;
    width: 15px !important;
    font-size: 15px !important;
}

.icon-md {
    height: 30px !important;
    width: 30px !important;
    font-size: 30px !important;
}

.icon-lg {
    height: 50px !important;
    width: 50px !important;
    font-size: 50px !important;
}

.whiteframe {
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
    background-color: #ffffff;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-dark {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-primary {
    background-color: $primary;
}

.bg-primary-gradient {
    background: linear-gradient(#42275a, #734b6d);
}

.bg-accent {
    background-color: $accent;
}

.bg-accent-gradient {
    background: $accent-gradient;
}

.bg-accent-alt {
    background-color: $accent400;
}

.bg-white {
    background-color: #ffffff;
}

.bg-light-grey {
    background-color: #e6e6e6;
}

.transparent-gradient {
    background: linear-gradient(to bottom, rgba(255, 255 ,255 ,0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
}

.rounded {
    border-radius: 3px;
}

.centered {
    text-align: center;
}

.img-contain {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.white-striped-border {
    border: dashed 3px #ffffff;
}

.pop-in-to-bottom {
    transform: scale3d(0, 0, 1);
    animation: pop-in-to-bottom 300ms forwards;
}

.circle {
    border-radius: 50%;
    overflow: hidden;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #c4c4c4;
}

.divider-v {
    height: 60%;
    width: 1px;
    background-color: #c4c4c4;
}

.fix-slide-position {
    transform: translate3d(0, 5px, 0);
}

.fab-bottom-right {
    position: absolute !important;
    bottom: 20px;
    right: 20px;
    top: auto;
    left: auto;
}

.fab-top-right {
    position: absolute !important;
    top: 5px;
    right: 5px;
    bottom: auto;
    left: auto;
}

.no-wrap {
    white-space: nowrap;
}

.fix-position-icon-md.mat-icon-button {
    line-height: 50px;
}

.dotted-border-top {
    border-top: 1px dotted #cccccc;
}

.dotted-border-bottom {
    border-bottom: 1px dotted #cccccc;
}

.dotted-border-right {
    border-right: 1px dotted #cccccc;
}

.time-segment-label {
    transform: translate3d(0px, -14px, 0px);
}

.clickable:hover {
    cursor: pointer;
}
